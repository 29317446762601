import { Box, Skeleton, styled } from '@mui/material';

const CustomSkeleton = styled(Skeleton)(({ theme }) => ({
  width: '100%',
}));

export function DepositWithdrawSkeleton() {
  return (
    <Box sx={{ position: 'relative' }}>
      <CustomSkeleton sx={{ height: '35px', width: '100%' }} />
      <CustomSkeleton sx={{ height: '40px' }} />
      <CustomSkeleton sx={{ height: '66px' }} />
      <CustomSkeleton sx={{ height: '61px' }} />
      <CustomSkeleton sx={{ height: '61px' }} />
      <CustomSkeleton sx={{ height: '66px' }} />
      <CustomSkeleton sx={{ height: '24px' }} />
      <CustomSkeleton sx={{ height: '56px' }} />
      <CustomSkeleton sx={{ height: '218px' }} />
      <CustomSkeleton sx={{ height: '55px' }} />
    </Box>
  );
}
